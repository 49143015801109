import React from 'react';

interface Props {
  alt: string;
  src: string;
}

function getYoutubeVideo(src: string): string | undefined {
  const [, hrefVideo] = src.split('watch?v=');
  const [, sharedVideo] = src.split('youtu.be/');

  return hrefVideo || sharedVideo;
}

const Image: React.FC<Props> = ({ alt, src }) => {
  const youtubeVideo = getYoutubeVideo(src);

  return youtubeVideo ? (
    <div className="post-content__image video-container s-mb-10 m-mb-15">
      <iframe
        className="video"
        title={alt}
        src={`https://www.youtube.com/embed/${youtubeVideo}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  ) : (
    <div className="post-content__image image-container s-mb-10 m-mb-15">
      <img className="image" src={src} alt={alt} />
    </div>
  );
};

export default Image;
