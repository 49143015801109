import React from 'react';
import { graphql, PageProps } from 'gatsby';
import Post from '../../modules/Post/Post';
import { default as IPost, EventDetails } from '../../types/Post';

interface Props {
  markdownRemark: {
    frontmatter: IPost & EventDetails;
    rawMarkdownBody: string;
  };
  allMarkdownRemark: {
    nodes: Array<{ frontmatter: IPost }>;
  };
}

const BlogPost: React.FC<PageProps<Props>> = ({ data }) => {
  const {
    markdownRemark: { frontmatter: post, rawMarkdownBody: content },
    allMarkdownRemark: { nodes: relatedPosts },
  } = data;

  return (
    <Post
      post={post}
      relatedPosts={relatedPosts.map((blogPost) => blogPost.frontmatter)}
      content={content}
    />
  );
};

export const query = graphql`
  query GetBlogPost($permalink: String!, $categories: [String!]) {
    markdownRemark(frontmatter: { permalink: { eq: $permalink } }) {
      frontmatter {
        ...PostsNodesCommonFields
        date(formatString: "MMMM Do, YYYY")
        exactDate: when(formatString: "dddd MMMM Do")
        exactHour: when(formatString: "hh:mm A")
      }
      rawMarkdownBody
    }
    allMarkdownRemark(
      filter: { frontmatter: { categories: { in: $categories }, permalink: { ne: $permalink } } }
      limit: 3
    ) {
      nodes {
        frontmatter {
          ...PostsNodesCommonFields
          when(formatString: "dddd MMMM Do, YYYY - hh:mm A")
        }
      }
    }
  }
`;

export default BlogPost;
