import React from 'react';

const Paragraph: React.FC = ({ children }) => (
  // TODO: make a further investigation about why the first paragraph
  // after an image is not getting the styles after page refresh
  // this hack of wrapping with another <p> seems to work for now
  <p>
    <p className="s-font-sz-14 m-font-sz-18 text-grey s-mb-6">{children}</p>
  </p>
);

export default Paragraph;
