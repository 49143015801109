import React from 'react';
import Prism from 'prismjs';
import ReactMarkdown from 'react-markdown';
import { graphql } from 'gatsby';
import Layout from '../../global/Layout';
import { default as IPost, Event, EventDetails } from '../../types/Post';
import Codeblock from './Codeblock';
import Headings from './Headings';
import List, { ListItem } from './List';
import Paragraph from './Paragraph';
import PostHeader from './PostHeader';
import Image from './Image';
import Link from './Link';
import SocialNetworks from './SocialNetworks';
import BlogPost from '../../global/BlogPost/BlogPost';
import PageContext from '../../contexts/PageContext';
import EventInformation from './EventInformation';
import EventPost from '../../global/BlogPost/EventPost';
import calendarIcon from '../../images/events/calendar.svg';
import pinIcon from '../../images/events/pin.svg';

interface Props {
  post: IPost;
  relatedPosts: Array<IPost>;
  content: string;
}

const Post: React.FC<Props> = ({ post, content, relatedPosts }) => {
  const isEvent = post.categories.includes('events');
  const event = post as Event & EventDetails;

  React.useEffect(() => {
    Prism.highlightAll();
  }, []);

  return (
    <PageContext.Provider value={{ currentPage: post.title }}>
      <Layout
        metaData={{ title: post.title, image: post.image }}
        header={<PostHeader post={post} isEvent={isEvent} />}
      >
        {isEvent && (
          <section className="event-post l-grid s-colum-1 m-column-2 s-c-gap-20 s-r-gap-10 s-mb-11 s-px-4 m-mx-25">
            <EventInformation
              title="When"
              subtitle={event.exactDate}
              details={event.exactHour}
              icon={calendarIcon}
            />
            <EventInformation
              title="Where"
              subtitle={event.where}
              details={event.city}
              icon={pinIcon}
            />
          </section>
        )}
        <ReactMarkdown
          className="post-content s-mx-4 m-mx-25 s-mb-13 m-mb-18 l-margin-center"
          source={content}
          renderers={{
            paragraph: Paragraph,
            heading: Headings,
            list: List,
            listItem: ListItem,
            code: Codeblock,
            image: Image,
            link: Link,
          }}
        />
        <div className="post-social-networks s-mb-15 m-mb-20">
          <SocialNetworks title={post.title} />
        </div>
        {relatedPosts && relatedPosts.length > 0 && (
          <section className="related-posts l-margin-center s-px-4 s-mb-20">
            <h2 className="font-w-xbold s-font-sz-20 m-font-sz-32 text-grey s-mb-8 m-mb-4">
              Related articles
            </h2>
            <div className="l-grid center s-column-1 m-column-2 l-column-3 s-c-gap-9 s-r-gap-15 m-r-gap-20 main-center">
              {relatedPosts.map((blogPost) => {
                const [category] = blogPost.categories;
                const isAnEvent = category === 'events';

                return isAnEvent ? (
                  <EventPost key={blogPost.permalink} event={blogPost as Event} category="events" />
                ) : (
                  <BlogPost key={blogPost.permalink} post={blogPost} category={category} />
                );
              })}
            </div>
          </section>
        )}
      </Layout>
    </PageContext.Provider>
  );
};

export const query = graphql`
  fragment PostsNodesCommonFields on MarkdownRemarkFrontmatter {
    title
    where
    city
    permalink
    categories
    author
    role
    image
  }
`;

export default Post;
