import React from 'react';

interface Props {
  href: string;
  title: string;
}

const Link: React.FC<Props> = ({ href, children }) => (
  <a
    className="text-primary link-underline-h s-font-sz-14 m-font-sz-18"
    href={href}
    target="_blank"
    rel="noreferrer"
  >
    {children}
  </a>
);

export default Link;
