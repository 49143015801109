import React from 'react';

interface Props {
  level: number;
}

const Headings: React.FC<Props> = ({ level, children }) => {
  switch (level) {
    case 1:
    case 2:
      return (
        <h2 className="s-font-sz-24 m-font-sz-32 font-w-xbold text-grey s-mb-1 m-mb-6">
          {children}
        </h2>
      );
    case 3:
      return (
        <h3 className="s-font-sz-16 m-font-sz-20 m-font-sz-24 font-w-bold text-primary s-mb-3">
          {children}
        </h3>
      );
    default:
      return <>{children}</>;
  }
};

export default Headings;
