import React from 'react';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import { FaFacebookSquare, FaTwitter, FaLinkedin } from 'react-icons/fa';

interface Props {
  title: string;
}

const SocialNetworks: React.FC<Props> = ({ title }) => {
  const url = typeof window !== 'undefined' ? window.location.href : '';

  return (
    <article>
      <p className="s-font-sz-12 text-grey font-w-bold s-mb-2">Share</p>
      <div className="post-social-networks-buttons">
        <FacebookShareButton url={url} quote={title}>
          <FaFacebookSquare className="s-mr-6 text-primary s-font-sz-24" />
        </FacebookShareButton>
        <TwitterShareButton url={url} title={title} via="s4n_co">
          <FaTwitter className="s-mr-6 text-primary s-font-sz-24" />
        </TwitterShareButton>
        <LinkedinShareButton url={url} title={title}>
          <FaLinkedin className="text-primary s-font-sz-24" />
        </LinkedinShareButton>
      </div>
    </article>
  );
};

export default SocialNetworks;
