import React from 'react';

interface Props {
  icon: string;
  title: string;
  subtitle: string;
  details: string;
}

const EventInformation: React.FC<Props> = ({
  icon, title, subtitle, details,
}) => (
  <article className="event-post__content">
    <img className="s-mr-5" src={icon} alt={title} />
    <div className="event-post__information">
      <p className="event-post__title s-font-sz-16 text-grey font-w-xbold s-mb-1 s-pb-1">{title}</p>
      <p className="s-font-sz-24 text-grey">{subtitle}</p>
      <p className="s-font-sz-18 text-grey">{details}</p>
    </div>
  </article>
);

export default EventInformation;
