import React from 'react';
import { FaFile } from 'react-icons/fa';

interface Props {
  language: string;
  value: string;
}

const Codeblock: React.FC<Props> = ({ language, value }) => {
  const [isCopied, setIsCopied] = React.useState(false);

  function copyCodeToClipboard() {
    navigator.clipboard.writeText(value).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 1000);
    });
  }

  return (
    <div className="post-content__code m-margin-center s-mb-10 m-mb-20">
      <div className="post-content__code-info s-px-5 s-py-2 m-py-4">
        <p className="l-font-sz-14 uppercase text-grey">{language}</p>
        <button
          type="button"
          className="s-font-sz-14 font-w-bold"
          disabled={isCopied}
          onClick={copyCodeToClipboard}
        >
          <FaFile className="s-mr-2 s-font-sz-20" />
          {isCopied ? 'COPIED' : 'COPY'}
        </button>
      </div>
      <pre className={`language-${language} line-numbers`}>
        <code className={`language-${language}`}>{value}</code>
      </pre>
    </div>
  );
};

export default Codeblock;
