import React from 'react';

interface Props {
  ordered: boolean;
}

const List: React.FC<Props> = ({ ordered, children }) => (ordered ? (
  <ol className="post-content__ordered-list s-mb-8 m-mb-20">{children}</ol>
) : (
  <ul className="post-content__unordered-list s-mb-8 m-mb-7">{children}</ul>
));

export const ListItem: React.FC = ({ children }) => {
  const baseClasses = 's-font-sz-14 m-font-sz-18 text-grey s-mb-8';
  const classes = React.Children.count(children) <= 1 ? baseClasses : `${baseClasses} inl-block`;
  return (
    <li className={classes}>{children}</li>
  );
};

export default List;
