import { Link } from 'gatsby';
import React from 'react';
import TwoLines from '../../global/Header/TwoLines';
import Post from '../../types/Post';
import SocialNetworks from './SocialNetworks';

interface Props {
  post: Post;
  isEvent: boolean;
}

const PostHeader: React.FC<Props> = ({ post, isEvent }) => (
  <>
    <div className="header-big-block s-mt-13 m-mt-20" />
    <div className="post-header-container">
      <TwoLines />
      <header className="post-header s-mx-4 m-mx-25 l-mx-20 s-mb-10 m-mb-17">
        <p className="l-font-sz-16 text-primary font-w-bold font-f-monserrat m-mb-6 uppercase">
          <Link to="/">S4N DECODED</Link>
        </p>
        <h1 className="post-header__title font-w-xbold l-font-sz-48 text-grey">{post.title}</h1>
        <section className="post-header__info">
          {!isEvent && (
          <article className="s-mb-5">
            <p className="l-font-sz-20 font-w-xbold text-grey m-mb-1">
              {post.author}
              {' '}
              -
              {' '}
              {post.role}
              {' '}
              at S4N
            </p>
            <time className="l-font-sz-18 text-grey">{post.date}</time>
          </article>
          )}
          <SocialNetworks title={post.title} />
        </section>
      </header>
    </div>
  </>
);

export default PostHeader;
